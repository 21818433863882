import { breakpoints } from '@pretto/bricks/components/layout'
import { column, grid } from '@pretto/bricks/components/layout/Grid'

import { ContentPage } from '@pretto/website/src/components/ContentPage/ContentPage'
import { NewBlocRow as NewBlocRowComponent } from '@pretto/website/src/templates/blocs/components/NewBlocRow'

import styled from 'styled-components'

export interface RowContentProps {
  markdownContent: string
  rowData: { databaseId: number; tagsData: unknown[] }
}

export const RowContent: React.FC<RowContentProps> = ({ markdownContent = '', rowData, ...props }) => (
  <NewBlocRow {...props}>
    <ContentWrapper>
      <ContentPage data={{ content: markdownContent, databaseId: rowData.databaseId }} tagsData={rowData.tagsData} />
    </ContentWrapper>
  </NewBlocRow>
)

const NewBlocRow = styled(NewBlocRowComponent)`
  ${grid()};
  background-color: ${({ theme }) => theme.colors.white};
`

const ContentWrapper = styled.div`
  ${column([2, 4])};

  @media screen and (min-width: ${breakpoints.tablet}) {
    ${column([3, 6])};
  }

  @media screen and (min-width: ${breakpoints.laptop}) {
    ${column([5, 6])};
  }
`
